const WhatsappData = {
    number: "+923018675410",
    message: "Hey, there Let's chat."
};
let info = {
    name: "Muhammad Ali Khalid",
    logo_name: "Muhammad Ali Khalid",
    flat_picture: require("./src/assets/me.png"),
    config: {
        use_cookies: false,
        navbar: {
            blur: false
        }
    },
    description:
        "Hi, I am Muhammad Ali Khalid, a Multi Stack Web developer with hands on experience. Being a Computer Science graduate, I have problem solving skills and software engineering ethics including Object Oriented Analysis and design. Good familiarity with Machine learning, NLP and computer vision algorithms.",
    links: {
        linkedin: "https://www.linkedin.com/in/alikhalid1/",
        github: "https://github.com/MuhammadAliKhalid/",
        resume: "./public/MuhammadAliKhalid.pdf",
        whatsapp: `https://api.whatsapp.com/send?phone=${WhatsappData.number}&text=${WhatsappData.message}`,
        upwork: "https://www.upwork.com/freelancers/~01732d27a60088a1a7",
        telegramUsername: "muhammadalikhalid",
        skype:"skype:alikhalidramay1?chat"
    },
    education: [
        {
            name: "The University of Faisalabad (Madina)",
            place: "Faisalabad",
            date: "2014 - 2018",
            degree: "Bachelor in Computer Science",
            gpa: "2.9/4.0",
            description:
                "",
            skills: [
                "Software Engineering",
                "Web & Mobile App Development",
                "Computer Vision",
                "Data Science",
                "Machine Learnig",
            ]
        },
        {
            name: "Chenab College Jhang",
            place: "Jhang",
            date: "2012 - 2014",
            degree: "I.C.S ",
            description:
                "",
            skills: [
                "Mathematics",
                "Physics",
                "Computer"
            ]
        },
        {
            name: "Divisional Public School & College",
            place: "Faisalabad",
            date: "2010 - 2012",
            degree: "Matric (Bio Science)",
            description:
                "",
            skills: [
                "Biology",
                "physics",
                "Chemistry",
            ]
        },
    ],
    experience: [
        {
            name: "OrderCircle",
            place: "Allied Consultants, Lahore, Pakistan",
            date: "Jul 2022 - present · 10mos",
            position: "Technical Team Lead",
            description:
                "I am managing the development of the new features in the OrderCircle B2B product and Leading the Technical Team. I made multi-warehouse management system with sync inventories accords. to each warehouses. Did integrations with Quickbooks, Shopify, etc, and Payment Modules such as Stripe, Authorize.net, and PayPal with Laravel and VueJs.",
            skills: ["Laravel", "VueJS", "JQuery", "JSON", "JavaSript", "Xdebug", "ES6", "Docker", "docker compose", "Git", "Jira", "Sourcetree", "Bitbucket", "PHP", "MySQL", "PHP Frameworks", "Relational Databases", "Laravel Forge", "Laravel Envoyer"]
        },
        {
            name: "OrderCircle",
            place: "Allied Consultants, Lahore, Pakistan",
            date: "Nov 2020 - Oct 2022 · 2 yrs ",
            position: "Front-end Developer",
            description:
                "I did order management system and worked with various Integrations in OrderCircle. I worked on reports with grid-ui in angular.js. I worked on both version of OrderCircle (v1, v2). OrderCircle is an expert in the B2B E-commerce space and hundreds of brands to manage their wholesale and B2B sales channels Web Application",
            skills: ["PHP Frameworks", "Relational Databases", "JavaScript Frameworks", "Laravel", "Vue.js", "AngularJS", "JavaScript", "Cascading Style Sheets (CSS)", "HTML5", "Materialize CSS", "Bootstrap", "metronic", "Git", "Jira", "Sourcetree", "Bitbucket", "Microsoft Visual Studio Code", "PhpStorm", "Workbench", "Stored Procedures", "Views", "Webpack", "MySQL", "jQuery"]
        },
        {
            name: "Bright Line Solutions (Pvt) Ltd",
            place: " Lahore, Pakistan",
            position: "Software Engineer",
            date: "Nov 2018 - Oct 2020 · 2 yrs ",
            description:
                "I did multiple projects (ERP, SFA, HRM, Facial Scanner, Task Management) in Bright Line Solutions (Pvt) Ltd. ",
            skills: ["HTML5", "Cascading Style Sheets (CSS)", "jQuery", "Javascript", "Core PHP", "CodeIgniter", "Laravel", "MySQL", "Android Design", "Android Development", "Android Studio", "Volley", "Retrofit", "python"]
        },
        {
            name: "Orthodox Web Solutions",
            place: " Lahore, Pakistan",
            date: "Jul 2018 - Nov 2018 · 5 mos",
            position: "Web and Mobile Application Developer",
            description:
                "I made 'Lets eat' app in android studio with using volley library and PHP API services as well I made student Management system in CodeIgniter (php framework).",
            skills: ["HTML5", "Cascading Style Sheets (CSS)", "jQuery", "Javascript", "Core PHP", "CodeIgniter", "MySQL", "Android Design", "Android Studio"]
        },
        {
            name: "Anaya Web Solution",
            place: " Faisalabad, Pakistan.",
            date: "Jan 2017 - Mar 2017 · 3 mos",
            position: "Web Application developer - Internship",
            description: "I Learn Core Php and HTML, CSS, javaScript Validation things. I made small modules of CRUD System with using HTML, CSS, JavaScript and I made customize Templates which only consist of HTML, CSS, JavaScript.",
            skills: ["HTML5", "Cascading Style Sheets (CSS)", "jQuery", "Javascript", "Core PHP", "MySQL"]
        },
        {
            name: "Freelancer",
            place: "Fiverr, Upwork",
            date: '5 or more jobs Completed with good rating and feedback.',
            position: "Full-stack Web and App developer",
            description: "Developed Laravel Project and done some bugfix projects and as well git version issued resolved jobs.",
            skills: ["HTML5", "Cascading Style Sheets (CSS)", "jQuery", "Javascript", "Core PHP", "MySQL", "Laravel", "Git", "Bug fixes", "Debugging (X debug, JavaScript debugger)", "MySQL", "Docker"]
        },
    ],
    skills: [
        {
            title: "UX/UI Design",
            info: ["Figma", "Illustrator", "Photoshop"],
            icon: "fa fa-pencil-square-o"
        },
        {
            title: "Language",
            info: [
                "PHP", "Javascript", "Java", "SQL", "Python"],
            icon: "fa fa-code"
        },
        {
            title: "Front-end",
            info: [
                "HTML5", "CSS3", "Bootstrap v3, v4, v5", "MaterializeCss", "JavaScript ES6", "metronic theme", "Vuetify"],
            icon: "fa fa-cubes"
        },
        {
            title: "Web Frameworks",
            info: ["Laravel", "CodeIgniter", "Vue", "React", "Django", "TensorFlow"],
            icon: "fas fa-laptop-code"
        },
        {
            title: "Mobile App Techs",
            info: ["Android Design using Constraints and Grid Layouts", "Retrofit", "Volley", "MVC", "Android Studio", "MySQL Lite"],
            icon: "fas fa-mobile"
        },
        {
            title: "DevOps techs",
            info: ["Docker", "Laravel Forge", "Laravel Envoyer", "Jira", "Azure", "GitHub", "BitBucket", "GitLab", "Sourcetree", "GitHub Desktop"],
            icon: "fas fa-gears"
        },
    ],
    portfolio_design: [
        {
            name: "Keep it Green",
            title: "UI/UX Design",
            pictures: [
                {
                    img: require("./src/assets/designs/Keep it green/cover.png"),
                    title: "MoodBoard"
                },
                {
                    img: require("./src/assets/designs/Keep it green/show.png"),
                    title: "show"
                },
            ],
            technologies: ["Figma", "Photoshop"],
            category: "Visual Design",
            github: "",
            date: "Jan, 2018",
            visit: "",
            description:
                ""
        },
        {
            name: "BOOKSI",
            title: "E-Book library - UI/UX Design",
            pictures: [
                {
                    img: require("./src/assets/designs/booksi/behance cover.png"),
                    title: "Cover"
                },
                {
                    img: require("./src/assets/designs/booksi/behance board.png"),
                    title: "MoodBoard"
                },
                {
                    img: require("./src/assets/designs/booksi/login.png"),
                    title: "login"
                },
                {
                    img: require("./src/assets/designs/booksi/register.png"),
                    title: "register"
                },
                {
                    img: require("./src/assets/designs/booksi/home page.png"),
                    title: "homepage"
                },
                {
                    img: require("./src/assets/designs/booksi/For you.png"),
                    title: "just for you"
                }
            ],
            technologies: ["Figma", "Photoshop"],
            category: "Visual Design",
            github: "",
            date: "Nov, 2019",
            visit: "",
            description:
                " BOOKSI is Ebook android app for easy read books."
        },
    ],
    portfolio: [
        {
            name: "OrderCircle v2",
            pictures: [
                {
                    img: require("./src/assets/portfolio/ordercircleV2/dashboard.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/create-orders.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/docs.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/integrations.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/payments.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/permissions.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/product-view.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/reports.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/settings.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV2/orders-list.png")
                }
            ],
            technologies: ["Laravel v7.x", "Vue.js v2", "MySQL", "Metornic Theme", "Bootstrap v4", "Laravel Forge", "Laravel Envoyer", "BitBucket", "JIRA", "Datatables.net", "Third-Party API Integrations"],
            category: "Web App",
            date: "Jan, 2021 - Present",
            github: "",
            visit: "https://orderdemo.v2.ordercircle.com/",
            description:
                "OrderCircle is an expert in the B2B E-commerce space. Its Developed using Laravel v7.x framework of PHP. We \n" +
                "created custom integration with different Products and API’s including Xero, Shopify, QuickBooks, Stripe, PayPal. \n" +
                "Created custom reports and created API to include these custom reports. I was part of the team who worked on this \n" +
                "Project. My role was to implement Order, Multi-Warehouse Inventory Management, Integrations, Reports Flow and improve the user experience of the Application. I handle all technical terms Like Deployments (Laravel Envoyer) and Developments."
        },
        {
            name: "OrderCircle v1",
            pictures: [
                {
                    img: require("./src/assets/portfolio/ordercircleV1/dashboard.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/docs.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/add-customers.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/add-products.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/orders-list.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/integrations.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/settings.png")
                },
                {
                    img: require("./src/assets/portfolio/ordercircleV1/payments.png")
                }
            ],
            technologies: ["Laravel v4.2", "Angular.js", "MySQL", "Bootstrap v3", "Laravel Forge", "BitBucket", "Trello", "Datatables.net", "Third-Party API Integrations"],
            category: "Web App",
            date: "Apr, 2021 - May, 2021",
            github: "",
            visit: "https://orderdemo.ordercircle.com/",
            description:
                "OrderCircle is an expert in the B2B E-commerce space. Its Developed using Laravel v4.2 framework of PHP. We \n" +
                "created custom integration with different Products and API’s including Xero, Shopify, QuickBooks, Stripe, PayPal. \n" +
                "Created custom reports and created API to include these custom reports. I was part of the team who worked on this \n" +
                "Project. My role was to implement Order, Inventory & Reports Flow and improve the user experience of the Application."
        },
        {
            name: "SamCart E-commerce and Affiliate",
            pictures: [
                {
                    img: require("./src/assets/no-image.jpg")
                },
            ],
            technologies: ["PHP", "Laravel", "VueJS", "AngularJS", "Xdebug", "CSS3", "Docker", "Docker Compose", "HTML5", "jQuery"],
            category: "Web Application",
            date: "Aug, 2021 - March 2022",
            github: "",
            visit: "https://www.samcart.com/",
            description:
                "SamCart is an E-commerce and Affiliate Management portal. Its Developed using PHP Laravel Framework and angular, \n" +
                "vue.js front-end frameworks. I was part of the team who worked on this Project. My role was to create some laravel test cases and tests \n" +
                "whole project through Xdebug and using PHP unit test cases of the Application with docker."
        },
        {
            name: "Consumer Court",
            pictures: [
                {
                    img: require("./src/assets/no-image.jpg")
                },
            ],
            technologies: ["PHP", "Codeigniter", "Bootstrap v3", "jQuery", "CSS3", "HTML5", "MySQL", "Datatables.net", "Third-Party API Integrations"],
            category: "Web Application",
            date: "Aug, 2021 - 10 days",
            github: "",
            visit: "https://consumerservice.punjab.gov.pk/",
            description:
                "Consumer Court is a Case Management portal. Its Court Cases Management Solution. Its Developed using PHP Codeigniter." +
                " We created Case Handling Solution. Created custom reports and created api to include these custom reports. I was part of the team who worked on this Project. My role was to implement Case, Auth, User Levels, Region hierarchy Flow and improve the user experience of the Application and I was leading this project in the team."
        },
        {
            name: " M Spares - Electronic E-commerce",
            pictures: [
                {
                    img: require("./src/assets/portfolio/mSpares/1.png")
                },
                {
                    img: require("./src/assets/portfolio/mSpares/2.png")
                },
            ],
            technologies: ["PHP", "Codeigniter", "Bootstrap v3", "jQuery", "CSS3", "HTML5", "MySQL", "Datatables.net", "Third-Party API Integrations"],
            category: "Web App",
            date: "June, 2019 - 2020",
            github: "",
            visit: "https://www.mspares.co.ke/",
            description:
                "M Spare is a Order Management portal. Its Ecommerce Solution. Its Developed using PHP Codeigniter. We created " +
                "custom integration with different Products and API’s including MyPay. Created custom reports and created api to " +
                "include these custom reports. I was part of the team who worked on this Project. My role was to implement Brand hierarchy, Order, Add to Cart, Integration of MyPay Flow " +
                "and improve the user experience of the Application."
        },
        {
            name: "Enterprise Resource Planning (ERP) Web Application",
            pictures: [
                {
                    img: require("./src/assets/no-image.jpg")
                },
            ],
            technologies: ["PHP", "Codeigniter", "Materializecss", "jQuery", "CSS3", "HTML5", "MySQL", "Server Side DataTables," +
            "Datepicker etc)"],
            category: "Web Application",
            date: "Aug, 2018 - 2020",
            github: "",
            visit: "",
            description:
                "Qarshi / JSML / Pharma ERP is an Enterprise Resource Planning (ERP) System. Its Developed using PHP Codeigniter. We " +
                "created Custom Product and its schemes & Inventory System & Fixed Assets & Accounting Ledgers & Digital Bank Checks" +
                "Records. Created custom reports and created api to include these custom reports. I was part of the team who worked" +
                "on this Project. My role was to implement Custom Product and its schemes & Inventory System & Fixed Assets & Accounting Ledgers & Digital Bank Checks Flow, Report Implementations and improve the user experience of the Application."
        },
        {
            name: "Facial Recognition Android Application",
            pictures: [
                {
                    img: require("./src/assets/no-image.jpg")
                },
            ],
            technologies: ["Python", "Android", "OpenCV Library"],
            category: "Android Application",
            date: "Aug, 2018 - 2020",
            github: "",
            visit: "",
            description:
                "Facial Recognition is an Android App. Its Employees Facial Recognition Solution. Its Developed using Android Studio & " +
                "Python. We created Android App using Python API. I was part of the team who worked on this Project. My role was to " +
                "implement OpenCv Library on Android app with using python Api for verification and improve the user experience of the Application."
        },
        {
            name: "Student Management System – LMS",
            pictures: [
                {
                    img: require("./src/assets/no-image.jpg")
                },
            ],
            technologies: ["PHP", "Codeigniter", "Bootstrap v3", "jQuery", "CSS3", "HTML5", "MySQL", "Datatables.net", "Third-Party API Integrations"],
            category: "Web Application",
            date: "Apr, 2018 - Nov 2018",
            github: "",
            visit: "https://school.ali-khalid.com",
            description:
                "Student Management System is a Student Attendance portal - LMS. Its Developed using CodeIgniter. we created custom module and reports to " +
                "include these custom reports. I was part of the team who worked on this Project. My role was to implement all " +
                "attendance, auth, leaves Flow and improve the user experience of the Application."
        },
        {
            name: "GYM Management System – GYM",
            pictures: [
                {
                    img: require("./src/assets/no-image.jpg")
                },
            ],
            technologies: ["Core PHP", "Bootstrap v3", "jQuery", "CSS3", "HTML5", "MySQL", 'Ajax', "Datatables.net"],
            category: "Web Application",
            date: "Apr, 2018 - Nov 2018",
            github: "",
            visit: "",
            description:
                "GYM Management System is a Customer Attendance portal. Its Developed using Core PHP. we created custom reports to " +
                "include these custom reports. I was part of the team who worked on this Project. My role was to implement all " +
                "attendance , auth Flow and improve the user experience of the Application."
        },
    ],
    certificates: [
        {
            name: "Android Mobile Application Development",
            pictures: [
                {
                    img: require("./src/assets/certificates/1.png")
                },
            ],
            technologies: ["Java", "XML", "Android Design", "Retrofit", "Volley", "MVC", "Android Studio", "MySQL Lite", "Constraints and Grid Layouts",],
            category: "Provider: CYBEX SCHOOL OF IT PROFESSIONALS",
            date: "Issued by july, 2017 - No Expiry",
            github: "",
            visit: "",
            description:
               "This is 3 monthly Certification, In this, they teach me about making designs in android studio and also we made RecycleView with a Constraints layout for an e-commerce app. we used volley and Retrofit for API service"
        },
    ],
};

export default info;
