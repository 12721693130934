<template>
  <div class="bg-secondary">
    <div class="container py-3">
      <div class="row pt-1 align-items-center">
        <div
          class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pbelow"
          style="color: white;"
        >
          <span>© 2023 Copyright: Muhammad Ali Khalid</span>
        </div>

        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <button
              class="btn btn-outline-secondary mx-2 "
              @click="open('linkedin')"
            >
              <i class="fab fa-linkedin"></i>
            </button>
            <button type="button"
                    class="whatsapp-button btn mx-2"
                    @click="open('whatsapp')"
                    v-tooltip.bottom="'WhatsApp'">
            </button>
            <button type="button"
                    class="upwork-button btn mx-2"
                    @click="open('upwork')"
                    v-tooltip.bottom="'UpWork'">
            </button>
            <button type="button"
                    class="btn mx-2 telegramim_button" :style="buttonStyle"
                    @click="open('telegram')"
                    v-tooltip.bottom="'telegram'">
              <i></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('github')"
              v-tooltip.bottom="'GitHub Profile'"
            >
              <i class="fab fa-github"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('resume')"
              v-tooltip.bottom="'CV'"
            >
              <i class="fa fa-file"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2 skype-button"
              @click="open('skype')"
              v-tooltip.bottom="'Skype'"
            >
              <img src="./../assets/skype/skype-icon.png" alt="Skype" srcset="">
            </button>
            <a href="mailto::alikhalidramay@gmail"
              class="btn btn-outline-secondary mx-2"
              v-tooltip.bottom="'Email'"
            >
              <i class="fa fa-envelope"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "../../info";

export default {
  name: "Footer-el",
  data() {
    return {
      linkedin: info.links.linkedin,
      github: info.links.github,
      behance: info.links.behance,
      resume: info.links.resume,
      whatsapp: info.links.whatsapp,
      upwork: info.links.upwork,
      skype: info.links.skype,
      telegramUsername: info.links.telegramUsername,
      buttonStyle: {
        fontSize:`13px`,
        width:`38px`,
        background: '#27A5E7',
        boxShadow: '1px 1px 5px #27A5E7',
        color: '#FFFFFF',
        borderRadius: '7px',
        display:`inline-block !important`,
      }
    };
  },
  mounted() {
    this.telegramButtonScript();
  },
  methods: {
    open(link) {
      switch (link) {
        case "linkedin":
          window.open(this.linkedin, "_blank");
          break;
        case "github":
          window.open(this.github, "_blank");
          break;
        case "behance":
          window.open(this.behance, "_blank");
          break;
        case "resume":
          this.makePDF();
          break;
        case "whatsapp":
          window.open(this.whatsapp, "_blank");
          break;
        case "upwork":
          window.open(this.upwork, "_blank");
          break;
        case "telegram":
          window.open(`https://telegram.im/@${this.telegramUsername}`, "_blank");
          break;
        case "skype":
          window.open(this.skype, "_blank");
        break;
      }
    },
    telegramButtonScript() {
      if(this.telegramUsername === "" || this.telegramUsername === null) {
        alert("Please, add username for telegram.");
        return;
      }
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = `//telegram.im/widget-button/index.php?id=@${this.telegramUsername}`
      document.getElementsByTagName('head')[0].appendChild(script)
    },
    makePDF(){
      let pdfLink = document.createElement('a');
      pdfLink.href = '/files/Muhammad-Ali-Khalid.pdf';
      pdfLink.setAttribute('download', 'MuhammadAliKhalid.pdf');
      document.body.appendChild(pdfLink);
      pdfLink.click();
    }
  },
};
</script>

<style scoped>
span {
  font-weight: 500;
}

.btn {
  border-color: white;
  color: white;
}

.btn:hover {
  background-color: white;
  border-color: white;
  color: gray;
}

.btn:focus {
  background-color: white;
  border-color: white;
  color: gray;
}


.whatsapp-button {
    background-image: url("../../src/assets/whatsapp-images/128px.png");
    height: 38px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
}
.upwork-button {
    background-image: url("../../src/assets/upwork/upwork.png");
    height: 38px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
}
.skype-button {
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
}
.skype-button > img {
  height: 25px;
  width: 25px;
  margin: 0px !important;
  transform: unset !important;
}

.skype-button:hover  {
  background-color: unset;
  border:none;
}
@media screen and (max-width: 580px) {
  .pbelow {
    padding-bottom: 20px;
    text-align: center;
  }
}
</style>
