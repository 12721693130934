<template>
  <div
    :class="{ 'bg-white': !nightMode, 'bg-dark': nightMode }"
    class="pt-5 p-st"
  >
    <div
      class="container"
      data-aos="fade"
      data-aos-once="true"
      data-aos-duration="1000"
    >
      <div class="row align-items-center">
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 text-center">
          <img :src="picture" />
        </div>
        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12 pt-5">
          <span
            class="home-title"
            :class="{ pgray: !nightMode, 'text-light': nightMode }"
            >Welcome to my portfolio!</span
          >
          <div>
            <p v-html="description"></p>
          </div>
          <div class="text-center pb-4">
            <button
              class="btn btn-outline-secondary mx-2 "
              @click="open('linkedin')"
              v-tooltip.bottom="'LinkedIn'"
            >
              <i class="fab fa-linkedin"></i>
            </button>
            <button type="button"
                    class="whatsapp-button btn mx-2"
                    @click="open('whatsapp')"
                    v-tooltip.bottom="'WhatsApp'">
            </button>
            <button type="button"
                    class="upwork-button btn mx-2"
                    @click="open('upwork')"
                    v-tooltip.bottom="'UpWork'">
            </button>
            <button type="button"
                    class="btn mx-2 telegramim_button telegramim_shadow" :style="buttonStyle"
                    @click="open('telegram')"
                    v-tooltip.bottom="'telegram'">
                <i></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('github')"
              v-tooltip.bottom="'GitHub'"
            >
              <i class="fab fa-github"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2"
              @click="open('resume')"
              v-tooltip.bottom="'CV'"
            >
              <i class="fa fa-file"></i>
            </button>
            <button
              class="btn btn-outline-secondary mx-2 skype-button"
              @click="open('skype')"
              v-tooltip.bottom="'Skype'"
            >
              <img src="./../assets/skype/skype-icon.png" alt="Skype" srcset="">
            </button>
            <a href="mailto::alikhalidramay@gmail"
              class="btn btn-outline-secondary mx-2"
              v-tooltip.bottom="'Email'"
            >
              <i class="fa fa-envelope"></i>
            </a>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import info from "../../info";
// import Wave from "./helpers/Wave";

export default {
  name: "Home-el",
  components: {
    // Wave,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      picture: info.flat_picture,
      description: info.description,
      name: info.name,
      linkedin: info.links.linkedin,
      github: info.links.github,
      behance: info.links.behance,
      resume: info.links.resume,
      whatsapp: info.links.whatsapp,
      upwork: info.links.upwork,
      skype: info.links.skype,
      telegramUsername: info.links.telegramUsername,
      buttonStyle: {
        fontSize:`13px`,
        width:`38px`,
        background: '#27A5E7',
        boxShadow: '1px 1px 5px #27A5E7',
        color: '#FFFFFF',
        borderRadius: '7px',
        display:`inline-block !important`,
      }
    };
  },
    mounted() {
      this.telegramButtonScript();
    },
    methods: {
    open(link) {
      switch (link) {
        case "linkedin":
          window.open(this.linkedin, "_blank");
          break;
        case "github":
          window.open(this.github, "_blank");
          break;
        case "behance":
          window.open(this.behance, "_blank");
          break;
        case "resume":
            this.makePDF();
          break;
        case "whatsapp":
          window.open(this.whatsapp, "_blank");
          break;
        case "upwork":
          window.open(this.upwork, "_blank");
          break;
        case "telegram":
          window.open(`https://telegram.im/@${this.telegramUsername}`, "_blank");
          break;
        case "skype":
        window.open(this.skype, "_blank");
        break;
      }
    },
      telegramButtonScript() {
          if(this.telegramUsername === "" || this.telegramUsername === null) {
              alert("Please, add username for telegram.");
              return;
          }
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.async = true
          script.src = `//telegram.im/widget-button/index.php?id=@${this.telegramUsername}`
          document.getElementsByTagName('head')[0].appendChild(script)
      },
      makePDF(){
        let pdfLink = document.createElement('a');
        pdfLink.href = '/files/Muhammad-Ali-Khalid.pdf';
        pdfLink.setAttribute('download', 'MuhammadAliKhalid.pdf');
        document.body.appendChild(pdfLink);
        pdfLink.click();
      }
  },
};
</script>

<style scoped>
.home-title {
  font-size: 32px;
  font-weight: 500;
}

img {
  max-width: 600px;
  max-height: 300px;
  margin-top: 80px;
  transform: rotateY(180deg);
}

@media only screen and (max-width: 580px) {
  img {
    object-fit: cover;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid rgb(205, 205, 205);
  }
}

.fa {
  font-size: 15px;
}

.btn {
  border-color: #759CC9;
  color: #759CC9;
}

.btn:hover {
  background-color: #759CC9;
  border-color: #759CC9;
  color: white;
}

.btn:focus {
  background-color: #759CC9;
  border-color: #759CC9;
  color: white;
}

.btn:focus {
  outline: none !important;
}

p {
  text-align: justify;
  font-weight: 400;
}

.whatsapp-button {
    background-image: url("../../src/assets/whatsapp-images/128px.png");
    height: 38px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
}
.upwork-button {
    background-image: url("../../src/assets/upwork/upwork.png");
    height: 38px;
    width: 38px;
    background-size: contain;
    background-repeat: no-repeat;
}
.skype-button {
  transition: 1s all ease;
  -webkit-transition: 1s all ease;
}
.skype-button > img {
  height: 25px;
  width: 25px;
  margin: 0px !important;
  transform: unset !important;
}

.skype-button:hover  {
  background-color: unset;
  border:none;
}
/* LEAVES */
</style>
